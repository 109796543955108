import { createAsyncThunk, thunkAPI } from '@reduxjs/toolkit' 
import { useDispatch } from 'react-redux';
import { apiBaseURL } from '../environment';
import { getBeers } from './beerService';
import { setBeerStatusToIdle } from '../features/beerlist/BeerSlice';
const axios = require('axios');

export const createUser = createAsyncThunk('users/addUser', async (params, thunkAPI) => {
    let urlEndPoint = apiBaseURL + '/users/addUser'
    try {
        const response = await axios.post(urlEndPoint);
        thunkAPI.dispatch(getBeers(response.data.userCode))
        return response.data;
    } catch(error) {
        return error;
    }
}) 

export const startUserSession = createAsyncThunk('users/getSingleUser', async (userCode, thunkAPI) => {
    let urlEndPoint = apiBaseURL + '/users/getUserByCode/' + userCode;
    try {
        let response;
        await axios.get(urlEndPoint).then(res => {
            response = res;
        });
        thunkAPI.dispatch(getBeers(userCode))
        return response.data
    } catch(error) {
        return error
    }
})

