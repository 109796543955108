import { createSlice } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { getBooths, getProgram } from '../../services/beerService'
import { Booth } from './Booth'

connect()(Booth)


export const initialStateBooths = {
    booths: [],
    boothStatus: 'idle',
};

export const BoothSlice = createSlice({
    name: 'booths',
    initialState: initialStateBooths,
    reducers: {
        doNothing(state) {
            return {
                ...state,
                boothStatus: 'idle',
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getBooths.pending, (state, action) => {
                return {
                    ...state,
                    boothStatus: 'loading'
                }
            })
            .addCase(getBooths.fulfilled, (state, action) => {
                return {
                    ...state,
                    boothStatus: 'succeeded',
                    booths: action.payload,
                }
            })
            .addCase(getBooths.rejected,  (state, action) => {
                return {
                    ...state,
                    boothStatus: 'failed',
                }
            })           
    }

});

export const selectBoothsStatus = (state) => state.booths.boothStatus;

export const selectBooths = (state) => state.booths.booths;

export default BoothSlice.reducer;
