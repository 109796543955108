import React, { useState, useRef, useEffect } from 'react'
import './styles/infomap.css'
import './styles/absolute-map.css'


import { MiniHeader } from './header';
import { ReactReduxContext } from 'react-redux';
import { getBooths } from "../../services/beerService";
import { useSelector, useDispatch } from 'react-redux';
import { selectBooths, selectBoothsStatus } from "../../features/boothlist/BoothSlice";
import BeerSlice from '../../features/beerlist/BeerSlice';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export default function InfoMapBlock(){

    const myRef = useRef(null)

    const { t } = useTranslation();

    const executeScroll = () => myRef.current.scrollIntoView(
        {
            behavior: "smooth", 
            block: "start", 
            inline: "nearest"
        })   

    function handleClick(viewSet){
        setActiveView(viewSet)
        setTimeout(function() {
            executeScroll()
        }, 100)

    }

    const [activeView, setActiveView] = useState(false)


    return(
        <>
        <div className='centerViewBlock'>
                <MiniHeader darkMode='true'/>
                <div className='viewBlock centerText'>
                    <h1>{t('Map of the area and information on the breweries and the food trucks')}</h1>
                    <div>
                        <p>{t("Below you may scroll the map or inspect the booths interactively.")}</p>
                        <p>
                            {t("You may also open the map as a")} <a href='./area-map.pdf' download className='orange-link'>{t("file")}</a>.
                        </p>
                    </div>
                    <div className='selectMap'>
                        <div className={activeView === 'map' ? 'SelButtonActive' : 'SelButton'} onClick={() => handleClick('map')}>
                            {t("Scroll map")}
                        </div>
                        <div className={activeView === 'offerings' ? 'SelButtonActive' : 'SelButton'} onClick={() => handleClick('offerings')}>
                            {t("Scroll offering")}
                        </div>
                    </div>
                </div>
            </div>
            <div className='centerViewBlock' ref={myRef}>
                { activeView === 'map' &&
                <GeneralMap/>                }
                { activeView === 'offerings' &&
                <OfferingMap/>
                }
            </div>
        </>
    )

}

function GeneralMap(){
    return(
            <div className='viewBlock infomapContainer'>
                <div className='mapBlockItem'>
                    <img src='/area-map.svg'  alt='area-map'/>
                    <div className='absolutePointers'>
                        <div className='absolute-wc'></div>
                    </div>
                </div>
            </div>
    )
}

function OfferingMap(){
    const { t } = useTranslation();

    const [activeElement, setActiveElement] = useState(false)
    const [beersInBooth, setBeersInBooth] = useState([])
    const [activeObject, setActiveObject] = useState(false)
    const [activeWeekendStatus, setActiveWeekendStatus] = useState(false)
    const [sideOfPop, setSideOfPop] = useState(false)

    const beerBooths = [1,2,3,4,5,6,7,8,9,10,11]
    const foodBoots = [
        {
            slot: 20,
            name: 'R1'
        },
        {
            slot: 21,
            name: 'R2'
        },
        {
            slot: 22,
            name: 'R3'
        },
    ]

    const dispatch = useDispatch()
    const boothStatus = useSelector(selectBoothsStatus);
    
    const generalPoint = useRef(null)
    const firstMapPoint = useRef(null)

    function executeScroll(toWhere) {
        toWhere.current.scrollIntoView(
        {
            behavior: "smooth", 
            block: "center", 
            inline: "nearest"
        })  
    } 


    /*
    const executeScroll = () => myRef.current.scrollIntoView(
        {
            behavior: "smooth", 
            block: "start", 
            inline: "nearest"
        })   
    */

    function handleClick(viewSet){
        setActiveElement(viewSet)
        setActiveObject(activeBreweries?.find(item => item.slot === viewSet) || activeRestaurants?.find(item => item.slot === viewSet))
        if(activeBreweries?.find(item => item.slot === viewSet)){
            setSideOfPop('left')
        } else {
            setSideOfPop('right')
        }
        setTimeout(function() {
            firstMapPoint.current.scrollIntoView(
                {
                    behavior: "smooth", 
                    block: "start", 
                    inline: "center"
                })  
        }, 100)

    }

    function handleActiveWeekend(selected) {
        setActiveWeekendStatus(selected)
        setTimeout(function() {
            executeScroll(generalPoint)
        }, 100)
    }
    
    useEffect(() => {
        if (boothStatus === 'idle') {
                dispatch(getBooths())
        }
        }, [boothStatus, dispatch]
    )
    const booths = useSelector(selectBooths);
    const activeWeekend = [activeWeekendStatus, 'BOTH']
    const activeBreweries = booths
        ?.filter(obj => { return activeWeekend.includes(obj.weekend);}) // filter only first and both
        ?.filter(obj => { return obj._id < 200}) // filter only breweries (id < 200)

    const activeRestaurants = booths
        ?.filter(obj => { return activeWeekend.includes(obj.weekend);}) // filter only first and both
        ?.filter(obj => { return obj._id >= 200}) // filter only restaurants (id >= 200)


    return(
        <>
        <div className='viewBlockNoWH'>
            <div className='selectMap selectWeekend'>
                <div className={activeWeekendStatus === 'FIRST' ? 'SelButtonActive' : 'SelButton'} onClick={() => handleActiveWeekend('FIRST')}>
                    {t("First weekend")}
                </div>
                <div className={activeWeekendStatus === 'SECOND' ? 'SelButtonActive' : 'SelButton'} onClick={() => handleActiveWeekend('SECOND')}>
                    {t("Second weekend")}
                </div>
            </div>
        </div>
        { activeWeekendStatus &&
            <>
                <div className='viewBlock centerText fullVhBlock' ref={generalPoint}>
                    <div className='breweryListBlock'>
                        <h1>{t("Drinks")}</h1>
                        {activeBreweries?.sort((a, b) => (a.slot > b.slot) ? 1 : -1).map((val, key) => 
                            <h3 key={key} onClick={() => handleClick(val.slot)}>{val?.slot+'. '+val?.name}</h3>
                        )}
                    </div>
                    <div className='foodListBlock'>
                        <h1>{t("Food")}</h1>
                        {activeRestaurants?.sort((a, b) => (a.slot > b.slot) ? 1 : -1).map((val, key) => 
                            <h3 onClick={() => handleClick(val.slot)} key={key}>{'R'+(key+1)+'. '+val?.name}</h3>
                        )}
                    </div>
                </div>
                <div className='viewBlock infomapContainer'>
                    <div className='mapBlockItem' >
                        <img src='/beer-map_2.svg' className={activeElement ? 'dimMap':''} alt='area-map'/>
                        <div className='absolutePointers' ref={firstMapPoint}>
                            {activeBreweries?.slice(0,4).map((val, key) => 
                                <div key={key} className={'absolute-'+val?.slot} onClick={() => handleClick(val?.slot)}>
                                    <div className={val?.slot === activeElement ? 'highlightElement' : ''}><h2>{val?.slot}</h2></div>
                                </div>
                            )}
                            {activeBreweries?.slice(4)?.map((val, key) => 
                                <div key={key} className={'absolute-'+val?.slot} onClick={() => handleClick(val?.slot)} >
                                    <div className={val?.slot === activeElement ? 'highlightElement' : ''}><h2>{val?.slot}</h2></div>
                                </div>
                            )}
                            {activeRestaurants.map((val, key) => 
                                <div key={key} className={'absolute-R'+val.slot} onClick={() => handleClick(val?.slot)}>
                                    <div className={val.slot === activeElement ? 'highlightElement' : ''}><h2>{'R'+(val.slot-19)}</h2></div>
                                </div>
                            )}
                        </div>
                        <div className={activeElement ? [sideOfPop === 'left' ?'sticky-infoscreen':'sticky-infoscreenFood '] : 'hide-infoscreen'} onClick={() => handleClick(false)}>
                            <div className='sticky-content'>
                                <h2 className='closeSticky' onClick={() => handleClick(false)}>X</h2>
                                { activeObject?.img && 
                                    <img src={'./breweries/'+activeObject?.image} alt='sticky-img'/>
                                }
                                <h2>{activeObject?.name}</h2>
                                {activeObject?.descriptionEN !== 'a' && <p>{activeObject?.descriptionEN}</p> }
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}