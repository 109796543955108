import { useDispatch, useSelector } from "react-redux";
import { addRaffleStyle, removeRaffleStyle, setRaffleStyleToInitial} from '../../features/beerlist/BeerSlice'
import './beerRaffle.css'


export function StyleCheckBox(props) {
    const styleName = props.styleName;
    const styleInRaffle = props.styleInRaffle
    const dispatch = useDispatch()
    const handleStyleChange = (event) => {
        if (event.target.checked === true) {
            dispatch(addRaffleStyle(styleName))
        } else {
            dispatch(removeRaffleStyle(styleName))
        }
    }

    function handleClick(){
        if(!styleInRaffle){
            dispatch(addRaffleStyle(styleName))
        } else {
            dispatch(removeRaffleStyle(styleName))
        }

    }
   
    return (
        <div className={styleInRaffle ? 'styleBox' : 'styleBox inactive-style'} onClick={() => handleClick()}>
            {styleName}
        </div>
    )
}

/*
            <input type="checkbox" name={styleName} value={styleName} onChange={(e) => handleStyleChange(e)} checked={styleInRaffle}/> 
*/