import { MiniHeader } from "./header";
import { UserModule } from "../../features/users/userModule";
import { Beers } from "../../features/beerlist/Beers";

import './styles/beerplaycontent.css'


export function BeerPlayContent(){
    return (
      <div className="centerViewBlock">
        <MiniHeader darkMode='true'/>
        <div className='viewBlock Beer-machine-container'>
            <Beers/>
          </div>
      </div>
    )
}