import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { getBeers } from '../../services/beerService';
import { createUser, startUserSession } from '../../services/userService'
import { selectCurrentUserCode } from './userSlice'
import { clearUserSession } from './userSlice'
import './user.css'
import { useTranslation } from 'react-i18next';

//import ReCAPTCHA from 'react-google-recaptcha';

export function UserModule() {
    const { t } = useTranslation();
    const [userCodeInput, setUserCodeInput] = useState('');
    //const [showLogin, setShowLogin] = useState(false)

    const dispatch = useDispatch()
    const currentUser = useSelector(selectCurrentUserCode)
    const handleUserCreate = () => {
        return dispatch(createUser())
    }
    const handleStartUserSession = () => {
        dispatch(startUserSession(userCodeInput))
    }
    const handleClearUserSession = () => {
        return dispatch(clearUserSession())
    }
       return (
        <div className='userModule'>
            {currentUser ? 
                <div className='displayLoginInfo'>
                    <h1>{t("Login or set up an account")}</h1>
                    <div className='displayLoginInfo-text'>
                        <p>{t("You are logged in with the code")} <h2>{currentUser}</h2></p>
                    </div>
                    <div className='displayLoginInfo-logout'>
                        <button onClick={handleClearUserSession}>{t("Log out")}</button> 
                    </div>
                </div>
            :
            <div className='login-container'>
                <h1>{t("Login or set up an account")}</h1>
                <p>{t("login_or_set_up_account_info")}</p>
                <div className='makeUser'>
                    <div onClick={handleUserCreate}>{t("Create account")}</div>
                </div>
                <p>{t("or")}</p>
                <div className='loginAs'>
                    <input value={userCodeInput} onChange={e => setUserCodeInput(e.target.value)} type="text" maxLength="4"></input>
                    <button onClick={handleStartUserSession}>{t("Log in")}</button>
                </div>
            </div>
            }
        </div>
    )
}
/** 
function DisplayCurrentUser({currentUser}){
    const { t } = useTranslation();
    return(
        <div className='displayLoginInfo'>
            <div className='displayLoginInfo-text'>
                <p>{t("You are logged in with the code")}: <h2>{currentUser}</h2></p>
            </div>
            <div className='displayLoginInfo-button'>...</div>
        </div>
    )

}
*/


export function UserPanelNavibar({setLoginActive}){

    const [userCodeInput, setUserCodeInput] = useState('')
    //const [showLogin, setShowLogin] = useState(false)
    const [userConsent, setUserConsent] = useState(false);
  
    const dispatch = useDispatch()
    const currentUser = useSelector(selectCurrentUserCode)
    const handleUserCreate = () => {
        return dispatch(createUser())
    }
    async function handleStartUserSession() {
        setLoginActive(false)
        let response = await dispatch(startUserSession(userCodeInput))
        if(response.payload.error !== undefined){
            alert('whoops, no account for this user')
            setCreateUserStatus(false)
        }
    }
    const handleClearUserSession = () => {
        return dispatch(clearUserSession())
    }
  
    const { t } = useTranslation();

    const [createUserStatus, setCreateUserStatus] = useState(false);
  
    const [showLoginInput, setShowLoginInput] = useState(false);
    const [copiedUser, setCopiedUser] = useState(false);
    function openInputView(){
        setLoginActive(true)
    }

    async function changeUserConsent(e) {
        setUserConsent(e.target.checked)
    }

    async function setCopiedUserFunction() {
        setCopiedUser(true); 
        setInterval(function() {
            setCopiedUser(false);
        }, 2000)
    }

    async function cancelLogin() {
        setCreateUserStatus(false)
    }

    const [loading, setLoading] = useState(false);
    const [displayConsent, setDisplayConsent] = useState(false);

    async function createUserFunction(){
        if(!displayConsent){
            setDisplayConsent(true)
        } else {
            setLoading(true)
            var response = await handleUserCreate()
            if(response) {
                setInterval(function() {
                    setLoading(false);
                }, 1000)
            }
            setUserConsent(false);
            setDisplayConsent(false)
        }
    }

    function handleConsent(result){
        if(result){
            createUserFunction()
        } else {
            setDisplayConsent(false)
        }
    }
    // <h3>Not logged in! { createUserStatus ? <span className='navi-black-underline' onClick={() => setCreateUserStatus(false)}>{t('Cancel')}</span> : ''}</h3>

    return (
        <>
        {
            displayConsent &&
            <div className='consentBG'>
                <div className='flexRowConsent'>
                    
                    <div className='padding-10-consent'>
                        <div className='consentText'>
                            {t('consentText')}
                        </div>
                    </div>
                    <div className='consentButtons'>
                        <div className='navi-user-button cancelButton' onClick={() => handleConsent(false)}>
                            Hylkää
                        </div>
                        <div className='navi-user-button' onClick={() => handleConsent(true)}>
                            Hyväksy
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            loading ?
            <>
                <>
                    <h3>{t("creating")}...</h3>
                </>
            </>
            :
            <>
                {
                    currentUser ? 
                    <>
                        <h3 >Hi {currentUser}</h3>
                        <div className='navi-login-part flexRowLogin'>
                            <div className={copiedUser ?'navi-user-button highlightButton' :'navi-user-button'} onClick={() => {navigator.clipboard.writeText(currentUser); setCopiedUserFunction()}}>
                                {copiedUser ? [t('Copied ')] : t('copy')} {currentUser}
                            </div>
                            <div className='navi-user-button' onClick={handleClearUserSession}>
                                {t("Log out")}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='navi-login-part'>
                            {
                                !createUserStatus ?
                                <>
                                    <div className='flexRowLogin'>
                                        <button className='navi-login-already navi-user-button' onClick={() => setCreateUserStatus(true)}>
                                            {t("Log in")}
                                        </button>
                                        <button  className='navi-login-create navi-user-button' onClick={() => createUserFunction()}>
                                            {t("Create account")}
                                        </button>
                                    </div>
                                    
                                </>
                                :
                                <>
                                    {
                                        createUserStatus ?
                                        <>
                                        <div className='navi-create-user'>
                                            <div className='flexRowLogin'>
                                                <input value={userCodeInput} 
                                                    onClick={() => openInputView()} 
                                                    onChange={e => setUserCodeInput(e.target.value)} 
                                                    className='loginInput' 
                                                    type="text" 
                                                    maxLength="4"
                                                    placeholder={t('E.g. AB12')}
                                                    ></input>
                                            </div>
                                            <div className='flexRowLogin'>
                                                <button className='navi-user-button' disabled={userCodeInput?.length !== 4 ? true : false} onClick={() => handleStartUserSession()}>{t("Log in")}</button>
                                                <button className='navi-user-button' onClick={() => cancelLogin()}>{t("Cancel")}</button>
                                            </div>
                                        </div>
                                        </>
                                        :
                                        <></>
                                    }
                                </>
                            }
                        </div>
                    </>
                }
            </>
            }
        </>
    )
}

