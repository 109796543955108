import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { InfoPage, MapPage, BeersPage, RafflePage, SchedulePage } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./services/i18n";
import { saveUsersState } from "./services/localStorageService";
import { debounce } from "debounce";

const container = document.getElementById('root');
const root = createRoot(container);

var vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', vh + 'px');

// here we subscribe to the store changes
store.subscribe(
  // we use debounce to save the state once each 800ms
  // for better performances in case multiple changes occur in a short time
  debounce(() => {
    saveUsersState(store.getState());
  }, 800)
);


root.render(
  //<React.StrictMode>
  <>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<InfoPage/>}/>
          <Route path="map" element={<MapPage/>}/>
          <Route path="beers" element={<BeersPage/>}/>
          <Route path='raffle' element={<RafflePage/>}/>
          <Route path='schedule' element={<SchedulePage/>}/>
          <Route path="hmm" element={<h1>Congraz on finding the hmm page</h1>}/>
          <Route path="*" element={<Navigate to="/" />}/>
        </Routes>
      </BrowserRouter>
    </Provider>
  </>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();