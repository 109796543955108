import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Beer, BeerWait } from '../beerlist/Beer';
import { selectBeerStyles, selectRandomBeer, setRaffleStyleToInitial, raffleBeerNumber, selectAtLeastOneStyleSelectedForRaffle, selectRaffledBeer, selectWeekend } from '../beerlist/BeerSlice';
import { StyleCheckBox } from './styleCheckbox';
import './beerRaffle.css'
import '../beerlist/beers.css'
import { useTranslation } from 'react-i18next';


export function BeerRaffle(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const styles = useSelector(selectBeerStyles)
    const randomBeer = useSelector(selectRaffledBeer);
    const beerStylesSelectedForRaffle = useSelector(selectAtLeastOneStyleSelectedForRaffle);
    const selectedWeekend = useSelector(selectWeekend);
    
    const [addQuery, setAddQuery] = useState(false);
    const [loadBeer, setLoadBeer] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);


    // Effect used only on 1st load of the component
    useEffect(() => {
            if (firstLoad && selectedWeekend !== 'BOTH') {
            dispatch(raffleBeerNumber())
            const timer = setTimeout(() => {
                setLoadBeer(true)
              }, 1000);
              setLoadBeer(false)
              setFirstLoad(false)
              return () => clearTimeout(timer);
        }
    }, [])

    // Only this can trigger raffle after the first load of the component 
    //  in the effect.
    const handleBeerRaffle = () => {
        dispatch(raffleBeerNumber())
        const timer = setTimeout(() => {
            setLoadBeer(true)
          }, 1000);
          setLoadBeer(false)
          return () => clearTimeout(timer);
    }
    
      
    return (
        <>
        <div className='minWidthBlock raffleArea'>
            <h1>{t("You next beer is")}</h1>
            {(randomBeer !== undefined || randomBeer !== null) && selectedWeekend !== 'BOTH' ?
                <div className="raffleLister beerList">
                    {loadBeer ?
                    <Beer beer={randomBeer}/>
                    :
                    <BeerWait/>
                    }
                </div>
                :
                <div>{   
                    selectedWeekend !== 'BOTH' ? 
                    <h2 className='alert-no-beers'>No more beers available.</h2>
                    :
                    <h2 className="alert-no-beers">Both weekends selected for raffle. Please specify either one of them above.</h2>
                }
                </div>
            }
            <div>
                {
                    // temporary
                    false && <h3 >{t("Looking good?")} <span>{t("Where to find it?")}</span></h3>
                }
                <h3>{t("yuck")} { randomBeer ? <>{randomBeer.style}</> : <> </>} 
                    {'? '}
                    <span onClick={() => setAddQuery(!addQuery)}>
                        {addQuery ?
                            <span onClick={() => dispatch(setRaffleStyleToInitial())}>
                                {t("Oops, don't filter any styles")}
                            </span>  
                            : 
                            t("Filter styles")}
                    </span>
                </h3>
            </div>
            {addQuery ? 
            <div className='styleQuery'>
                <h2>{t("Filter beers in the raffle by their style")}</h2>
                <div className='styleQueryList'>
                    {
                    styles.sort().map(style => 
                        <StyleCheckBox key={style.style} styleName={style.style} styleInRaffle={style.inRaffle}/>
                    )}
                </div>
            </div>
            :
            <></>
            }
            <div onClick={() => [!beerStylesSelectedForRaffle || selectedWeekend === 'BOTH' ? '' :handleBeerRaffle()]} className={!beerStylesSelectedForRaffle || selectedWeekend === 'BOTH' ? 'raffleButton-bottom disabledButton' : 'raffleButton-bottom'} 
                >
                    {t('raffle')}{beerStylesSelectedForRaffle}
            </div>
            {!beerStylesSelectedForRaffle ? 
                <div>
                    {t("Please select at least one beer style to raffle a beer.")}
                </div> : <></>
            }
            {selectedWeekend === 'BOTH' ?
                <div>
                    {t("Please select either first or the second weekend above to raffle a beer.")}
                </div> : <></>
            }
        </div>
        </>
    )
}
