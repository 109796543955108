import { selectBeersByWeekend, selectBeersStatus, changeWeekendView, updateBeerFilterString, selectWeekend } from "./BeerSlice";
import { Beer } from "./Beer";
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react'
import { getBeers } from "../../services/beerService";
import { selectCurrentUserCode } from '../users/userSlice';
import './beers.css';
import './beerFilters.css';
import { BeerRaffle } from "../../features/beerRaffle/beerRaffle";
//import { Link } from 'react-router-dom';
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

export function Beers() {
    const dispatch = useDispatch()
    const beerStatus = useSelector(selectBeersStatus);
    const currentUserCode = useSelector(selectCurrentUserCode);
    const selectedWeekend = useSelector(selectWeekend);
    const myRef = useRef(null)

    const [displayMode, setDisplayMode] = useState(false)

    function handleDisplayMode(newvalue){
        if(newvalue === 'raffle') {
            //do raffle things
            setDisplayMode('raffle')
            openBeerRaffle()
        }
        if(newvalue === 'view') {
            setDisplayMode('view')
        }
    }

    const { t } = useTranslation(); 

    function openBeerRaffle(){
            setTimeout(function () {
                    myRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})  
            }, 100);
                
    }

    useEffect(() => {
        if (beerStatus === 'idle') {
            if (currentUserCode === undefined) {
                dispatch(getBeers())
            } else {
                dispatch(getBeers(currentUserCode))
            }
        }
        }, [beerStatus, currentUserCode, dispatch])

    const beers = useSelector(selectBeersByWeekend);
    
    return (
        <>
        <div className="centeredBeerTitle">
            <div className="beersIntroContainer">
                <h1>{t("alright")}</h1>
                </div>
                <p>{t("scroll_beers")}</p>
                <h2>1. {t("Select weekend")}</h2>
                <WeekendSelector/> 
                <h2>2. {t("Select what do you want do")}</h2>
                <div className='selectDisplayMode'>
                    <div onClick={() => handleDisplayMode('raffle')} className={displayMode === 'raffle' ? 'activeMode' : ''}>{t("Raffle beer")}</div>
                    <div onClick={() => handleDisplayMode('view')} className={displayMode === 'view' ? 'activeMode' : ''}>{t("Scroll beers")}</div>
                </div>
            </div>
            <>
            {
                displayMode === 'raffle' && 
                <div ref={myRef}>
                    {
                    selectedWeekend === 'BOTH' ?
                        <div className="beerONautWarning">
                            <div className="warnPart">
                                <p className="warn">
                                    !
                                </p>
                                <p className="warnMessage">
                                    {t("To raffle a beer, please select either the first or the second weekend above.")}
                                </p>
                            </div>
                        </div>
                    :
                    <BeerRaffle id='beerRaffle'/>
                }
                </div>
            }
            {
                displayMode === 'view' &&
                <>
                    <div className="beerList">
                        <div className="margin-10-percent">
                            <BeerSearch/>
                        </div>
                        {
                            beers.length >= 1 ?
                        beers.map((beer, key) => (
                            <Beer key={beer.name+key} beer={beer} />
                        ))
                    :
                        <div className="noBeersMatching">
                            <h4>{t("No beers matching this query")}</h4>
                        </div>
                    }
                    </div>
                </>
            }
            </>
        </>
    )
}   

export function WeekendSelector({type = 'default'}) {
    const dispatch = useDispatch();

    const [selectedWeekend, setSelectedWeekend] = useState('BOTH')

    function changeWeekend(input) {
        let wk = input.target.value
        setSelectedWeekend(wk)
        dispatch(changeWeekendView(wk));
    }

    const { t } = useTranslation(); 

    if (type === 'default') {
        return (
            <>
            <div onChange={(e) => changeWeekend(e)} className='selectWeekends'>
                <div className={ selectedWeekend === 'BOTH' ? 'activeWeekend' : ''}>
                    <input type="radio" id="both" name="weekend" value="BOTH" defaultChecked="true"/>
                    <label className="boldLabel" htmlFor="both">{t('all')}</label>
                </div>
                <div className={ selectedWeekend === 'FIRST' ? 'activeWeekend' : ''}>
                    <input type="radio" id="first" name="weekend" value="FIRST"/>
                    <label className="boldLabel" htmlFor="first">{t('Weekend 1')}</label>
                </div>
                <div className={ selectedWeekend === 'SECOND' ? 'activeWeekend' : ''}>
                    <input type="radio" id="second" name="weekend" value="SECOND"/>
                    <label className="boldLabel" htmlFor="second">{t('Weekend 2')}</label>
                </div>
                </div>
            </>
        )
    }
    else {
        return (
            <>
            <div onChange={(e) => changeWeekend(e)} className='selectWeekends'>
                <div className={ selectedWeekend === 'FIRST' ? 'activeWeekend' : ''}>
                    <input type="radio" id="first" name="weekend" value="FIRST"/>
                    <label className="boldLabel" htmlFor="first">{t('Weekend 1')}</label>
                </div>
                <div className={ selectedWeekend === 'SECOND' ? 'activeWeekend' : ''}>
                    <input type="radio" id="second" name="weekend" value="SECOND"/>
                    <label className="boldLabel" htmlFor="second">{t('Weekend 2')}</label>
                </div>
                </div>
            </>
        )
    }
    /**
     *             <div onChange={(e) => changeWeekend(e)}>
                <input type="radio" id="both" name="weekend" value="BOTH" defaultChecked="true"/>
                <label className="boldLabel" htmlFor="both">{t('all')}</label>
                <input type="radio" id="first" name="weekend" value="FIRST"/>
                <label className="boldLabel" htmlFor="first">{t('Weekend 1')}</label>
                <input type="radio" id="second" name="weekend" value="SECOND"/>
                <label className="boldLabel" htmlFor="second">{t('Weekend 2')}</label>
            </div>

            <div className='selectWeekends'>
            <div onClick={() => handleSelectWeekend('BOTH')} className={ selectedWeekend === 'BOTH' ? 'activeWeekend' : ''}>
                {t('all')}
            </div>
            <div onClick={() => handleSelectWeekend('FIRST')} className={ selectedWeekend === 'FIRST' ? 'activeWeekend' : ''}>
                {t('Weekend 1')}
            </div>
            <div onClick={() => handleSelectWeekend('SECOND')} className={ selectedWeekend === 'SECOND' ? 'activeWeekend' : ''}>
                {t('Weekend 2')}
            </div>
        </div>
     * 
     * 
     * 
     */
}

export function BeerSearch() {
    const dispatch = useDispatch();
    const { t } = useTranslation(); 
    function handleFilterChange(event) {
        dispatch(updateBeerFilterString(event.target.value))
    }   
    return (
        <div className="searchInputContainer">
            <input className="searchInput" 
                type="text" 
                defaultValue="" 
                onChange={(e) => handleFilterChange(e)}
                placeholder="Search by beer name, style or brewery"/>
        </div>
    )
}