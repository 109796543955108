import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// Instructions:
// https://phrase.com/blog/posts/localizing-react-apps-with-i18next/
// To add translations to component see example in info.js file.
const resources = {
    en: {
        translation: {
            app_name: "beer app",
            // Info section
            "Welcome to the virtual beer list and area info of Ota Olut beer festival!": "Welcome to the virtual beer list and area info of Ota Olut beer festival!",
            "Scroll down and learn more": "Scroll down and learn more",
            "Instructions for virtual beer list": "Instructions for virtual beer list",
            set_up_instructions_0: "Purchase your tickets to Ota Olut at",
            set_up_instructions_1: "You may set up an account in the panel below the instructions. This way you can keep track on the beers you have tasted. Click 'Create account' to receive a four-digit identifier. Your device will remember the code, but just in case, save the code for yourself for future use. The code is applicable on both weekends of Ota Olut.",
            personal_data_promise: "We do not collect any personal information for your convenience. Hence we do not expect you to fill in any personal nor personificating information.",
            app_features_1: "This application allows you to scroll and filter available beers as well as raffle the next beer to taste. These features are available in 'Beers' section.",
            app_features_2: "Setting up a user gives you the best user experience because you can easily keep track of the tasted beers even between the two weekends of Ota Olut.",
            map_feature: "You may also view the map of the event area to discover available services and breweries.",
            pwa_feature: "The website can be saved to your mobile phone's start screen to give you an app-like feel. This can be done in your browser's menu by clicking 'Save to start screen'.",
            // Header section
            "You are logged in as": "You are logged in as",
            "You are not logged in.": "You are not logged in.",
            home: "Home",
            info: "Info",
            map: "Map",
            schedule: "Schedule",
            beers: "Beers",
            // Area map section
            exit: "Exit",
            entrance: "Entrance",
            wc: "WC",
            seating: "Seating area",
            brewery: "Brewery",
            "Information about the brewery": "Information about the brewery",
            "URL to Untappd": "URL to Untappd",
            // Footer section
            "Get your ticket now at: ": "Get your ticket now at: ",
            // Single beer component
            cancel: "Cancel",
            taste: "Taste",
            close: "Close",
            // Beers component
            alright: "Alright",
            scroll_beers: "It's a great place to view the beers, isn't it? Get started by selecting the weekend and find a beer of your taste. You may also click the beer for more information.",
            raffle_beer_intro: "Too many intriguing options? Click below to raffle your next beer to taste.",
            close_beeronator: "Close Beer-o-nator",
            raffle_next_beer: "I'm feeling lucky!",
            // Beer raffle component
            "You next beer is": "You next beer is",
            "Looking good?": "Looking good?",
            "Where to find it?": "Where to find it?",
            yuck: "Yuck",
            "Oops, don't filter any styles": "Oops, don't filter any styles",
            "Filter styles": "Filter styles",
            "Filter beers in the raffle by their style": "Filter beers in the raffle by their style",
            "Log in or set up an account": "Log in or set up an account",
            "You are logged in with the code": "You are logged in with the code",
            "Log out": "Log out",
            login_or_set_up_account_info: "In case you haven't got an account yet, click 'Create account' below or in the menu views to set up an account and login. Don't forget to save your four-digit code!",
            "Create account": "Create account",
            or: "or",
            creating: "Creating user",
            "Log in": "Log in",
            "Please select at least one beer style to raffle a beer": "Please select at least one beer style to raffle a beer.",
            all: "All beers",
            "Weekend 1": "Weekend 1",
            "Weekend 2": "Weekend 2",
            "Please select either first or the second weekend above to raffle a beer.": "Please select either first or the second weekend above to raffle a beer.",
            raffle: "Raffle",
            copy: "Copy",
            "Copied ": "Copied ",
            "consentText": "Creating account generates you a personal four-character code that allows you flag your favourite drinks and keep track of your tasted drinks. To view the drinks, creating account is not required. Anyone with your four-character code may view the flagged and tasted beers of mine, however, it is assured that no personal nor identifying information will be saved nor available to third parties. Furthermore, the obtained usage data will not be used nor shared to third parties, and it will be deleted within reasonable time after the Ota Olut festival. By creating an account you agree with the above terms.",
            "agreeConsent": "I have read and agree to below terms to create an account.",
            "Map of the area and information on the breweries and the food trucks": "Map of the area and information on the breweries and the food trucks",
            "Below you may scroll the map or inspect the booths interactively.": "Below you may scroll the map or inspect the booths interactively.",
            "You may also open the map as a": "You may also open the map as a",
            file: "file",
            "Scroll map": "Scroll map",
            "Scroll offering": "Scroll offering",
            "Drinks": "Drinks",
            "Food": "Food",
            "Select weekend": "Select weekend",
            "Select what do you want do": "Select what do you want do",
            "No beers matching this query": "No beers matching this query",
            "First weekend": "First weekend",
            "Second weekend": "Second weekend",
            "Raffle beer": "Raffle beer",
            "Scroll beers": "Scroll beers"
        },
    },
    fi: {
        translation: {
            app_name: "kalja-appi",
            // Info section
            "Welcome to the virtual beer list and area info of Ota Olut beer festival!": "Tervetuloa Ota Oluen virtuaaliseen olutvihkoon ja alueinfoon!",
            "Scroll down and learn more": "Scrollaa alas ja tutustu",
            "Instructions for virtual beer list": "Ohjeet virtuaaliseen olutvihkoon",
            set_up_instructions_0: "Osta lippusi Ota Olueen osoitteessa ",
            set_up_instructions_1: "Voit luoda käyttäjän paneelista tämän infon alla. Klikkaamalla \"Luo tunnus\" sinulle generoidaan nelimerkkinen tunnus, jolle voit kirjata maistamasi oluet. Laitathan tämän tunnuksen talteen! Sama tunnus toimii kumpanakin viikonloppuna.",
            personal_data_promise: "Meitä ei kiinnosta henkilökohtainen datasi, joten sinun ei tarvitse syöttää mitään henkilökohtaisia ja personifikoivia tietoja. Olet siis GDPR-turvassa.",
            app_features_1: "Sovelluksella voi selata, rajata ja arpoa itselleen seuraavan oluen. Nämä toiminnot löydät \"Oluet\"-sivulta.",
            app_features_2: "Parhaan käyttäjäkokemuksen saat kirjautumalla sisään, sillä voit helposti pitää kirjaa maistamistasi oluista molempien Ota Olut-viikonloppujen välillä.",
            map_feature: "Palvelussa on lisäksi kartta, jolla voit tutkia Ota Oluen aluetta.",
            pwa_feature: "Sivu on mahdollista tallentaa sovelluksen kaltaiseksi pikakuvakkeeksi puhelimesi aloitussivulle. Tämä onnistuu helpoiten selaimesi valikosta klikkaamalla \"Lisää aloitusnäytölle\".",
            // Header section
            "You are logged in as": "Olet kirjautunut tunnuksella",
            "You are not logged in.": "Et ole kirjautunut sisään.",
            home: "Etusivu",
            info: "Info",
            map: "Kartta",
            schedule: "Ohjelma",
            beers: "Oluet",
            // Area map section
            exit: "Uloskäynti",
            entrance: "Sisäänkäynti",
            wc: "WC",
            seating: "Istuma-alue",
            brewery: "Panimo",
            "Information about the brewery": "Tietoa panimosta",
            "URL to Untappd": "Untappd-sivu",
            // Footer section
            "Get your ticket now at: ": "Osta lippusi nyt: ",
            // Single beer component
            "cancel": "Peru",
            "taste": "Maista",
            "close": "Sulje",
            // Beers component
            alright: "Nonnih",
            scroll_beers: "Tässä kelpaapi selailla kaleseja. Valitse ensin viikonloppu ja etsi mieleisesi olut. Voit myös klikata oluita nähdäksesi lisätietoja.",
            raffle_beer_intro: "Äh, liikaa hyviä vaihtoehtoja? Voit myös halutessasi arpoa itsellesi seuraavan olusen painamalla tuosta:",
            close_beeronator: "Sulje kaljakone",
            raffle_next_beer: "Lottoa seuraava kaljeesi!",
            // Beer raffle component
            "You next beer is": "Seuraava oluesi on",
            "Looking good?": "Näyttää hyvältä?",
            "Where to find it?": "Mistä tätä saa?",
            yuck: "Hyi",
            "Oops, don't filter any styles": "Eiku hups älä rajaakaan",
            "Filter styles": "Rajaa tyylejä",
            "Filter beers in the raffle by their style": "Rajaa arvottavia oluita tyylin mukaan",
            "Log in or set up an account": "Kirjaudu tai tee tunnus",
            "You are logged in with the code": "Olet kirjautunut tunnuksella",
            "Log out": "Kirjaudu ulos",
            login_or_set_up_account_info: "Jos et ole vielä luonut tiliä, klikkaa alhaalta tai valikkonäkymästä \"Luo tunnus\"luodaksesi tunnuksen ja kirjautuaksesi sisään. Älä unohda tallentaa nelikirjaimista koodiasi!",
            "Create account": "Luo tunnus",
            or: "tai",
            creating: "Luodaan käyttäjää",
            "Log in": "Kirjaudu",
            "Please select at least one beer style to raffle a beer": "Valitse ainakin yksi olut-tyyli arpoaksesi seuraavan oluen.",
            all: "Kaikki oluet",
            "Weekend 1": "1. viikonloppu",
            "Weekend 2": "2. viikonloppu",
            "Please select either first or the second weekend above to raffle a beer.": "Valitse yltä joko ensimmäinen tai toinen viikonloppu arpoaksesi oluen.",
            raffle: "Arvo uusiksi",
            copy: "Kopioi",
            "Copied ": "Kopioitu ",
            "consentText": "Luomalla käyttäjän sinulle generoidaan henkilökohtainen nelikirjaiminine koodi, jonka avulla voit pitää kirjaa suosikeistasi sekä maistamistasi oluista. Selataksesi juomia tunnuksen luominen ei ole pakollista. Kuka tahansa voi koodillasi selata merkitsemiäsi oluita, mutta sovellus ei tallenna mitään identifiovia henkilötietoja. Käyttäjädataa ei myöskään luovuteta kolmansille osapuolille ja kerääntynyt data poistetaan sovelluksesta kohtuullisessa ajassa festivaalin jälkeen. Luomalla käyttäjän sitoudut mainittuihin ehtoihin.",
            "agreeConsent": "Olen lukenut alla olevat ehdot ja hyväksyn ne.",
            "Map of the area and information on the breweries and the food trucks": "Aluekartta ja infoa panimoista sekä ruokapaikoista",
            "Below you may scroll the map or inspect the booths interactively.": "Alla voit selata yleistä aluekarttaa tai tutkia myyntipisteiden sijantia interaktiivisesti.",
            "You may also open the map as a": "Voit myös avata kartan",
            file: "tiedostona",
            "Scroll map": "Selaa aluekarttaa",
            "Scroll offering": "Selaa tarjontaa",
            "Drinks": "Juomat",
            "Food": "Ruoka",
            "Select weekend": "Valitse viikonloppu",
            "Select what do you want do": "Valitse mitä haluat tehdä",
            "No beers matching this query": "Mikään olut ei täsmää haun kanssa.",
            "First weekend": "1. viikonloppu",
            "Second weekend": "2. viikonloppu",
            "Raffle beer": "Arvo olut",
            "Scroll beers": "Selaa oluita"
        },
    },
};
i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "fi",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;