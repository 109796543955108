import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/users/userSlice'
import beerReducer from '../features/beerlist/BeerSlice';
import boothReducer from '../features/boothlist/BoothSlice';
import { loadUsersState } from '../services/localStorageService';
import programReducer from '../features/boothlist/programSlice';


export const store = configureStore({
  reducer: {
    users: userReducer,
    beers: beerReducer,
    booths: boothReducer,
    program: programReducer
  },
  preloadedState: 
  {
    beers: beerReducer.initialState,
    booths: boothReducer.initialState,
    program: programReducer.initialState,
    users: loadUsersState() !== undefined ? loadUsersState() : userReducer.initialState
  }
});
