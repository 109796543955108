import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectBooths, selectBoothsStatus } from "../../features/boothlist/BoothSlice";
import { getBooths } from "../../services/beerService";

export function Booth({booth}) {
    const dispatch = useDispatch()
    const beerStatus = useSelector(selectBoothsStatus);
    useEffect(() => {
        if (beerStatus === 'idle') {
                dispatch(getBooths())
        }
        }, [beerStatus, dispatch]
    )

    const booths = useSelector(selectBooths);
    return (
        <div>Booth dummy, i dont know why</div>
    )
}
