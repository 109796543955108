import { t } from 'i18next'
import './styles/footer.css'
import { useTranslation } from "react-i18next";


export function Footer(){
  const { t } = useTranslation();
    return(
      <div className='App-footer'>
            <div>
              {t("Get your ticket now at: ")}
            </div>
            <a className='external-url' href='https://otaolut.fi/' target="_blank" rel="noopener noreferrer">otaolut.fi</a>
            {/*<div>
              <p>Tämän härvelin ovat koodanneet Joel ja Tuukka. Jos sovelluksesta herää kysymyksiä tai haluat osoittaa palautetta allekirjoittaneille, voit lähestyä meitä sähköpostitse.</p>
            </div>
            */}
            <img src='/icons/otaolut_logoteksti_offwhite.svg' alt='logo'/>
        </div>
    )
  }