import React, { useEffect } from 'react'
import './styles/info.css'
import { MiniHeader } from './header'
import { AreaMap, BreweryMap, FoodMap} from './areamap'
//import { UserModule } from '../../features/users/userModule'
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import breweriesJSON from "./brewerylist.json";
import InfoMapBlock from '../general/InfoMapBlock'

//import { selectBooths } from "../../features/boothlist/BoothSlice";


// Redux for brewery etc info
import { useSelector, useDispatch } from 'react-redux';

//import { selectBeers, selectBeersStatus } from "../../features/beerlist/BeerSlice";
import { selectBooths, selectBoothsStatus } from "../../features/boothlist/BoothSlice";
import { getBooths } from "../../services/beerService";

export function InfoBlock(){
    const { t } = useTranslation();
    return (
        <div className='centerViewBlock'>
            <MiniHeader darkMode='true'/>
            <div className='welcomePage'>
                <div className='welcome-img-div'>
                    <img src='/area-img.JPG' alt='area-img'/>
                    <div className='welcome-img-mask'></div>
                </div>
                <h1>{t("Welcome to the virtual beer list and area info of Ota Olut beer festival!")}</h1>
                <p>{t("Scroll down and learn more")}</p>
            </div>
            <div className='viewBlock'>
                <Instructions />
            </div>
        </div>
    )
}

export function AreaInfo(){

    const dispatch = useDispatch()
    const boothStatus = useSelector(selectBoothsStatus);
    
    
    useEffect(() => {
        if (boothStatus === 'idle') {
                dispatch(getBooths())
        }
        }, [boothStatus, dispatch]
    )
    const booths = useSelector(selectBooths);
    // THE REST

    {/* 
    const activeWeekend = ['FIRST', 'BOTH']
    const activeBreweries = booths
        ?.filter(obj => { return activeWeekend.includes(obj.weekend);}) // filter only first and both
        ?.filter(obj => { return obj._id < 200}) // filter only breweries (id < 200)

    const activeRestaurants = booths
        ?.filter(obj => { return activeWeekend.includes(obj.weekend);}) // filter only first and both
        ?.filter(obj => { return obj._id >= 200}) // filter only restaurants (id >= 200)
    
    */}
        return (
            <InfoMapBlock />
    )
}

/*

<AreaMap activeBreweries={activeBreweries} activeRestaurants={activeRestaurants}/>
                <BreweryMap activeBreweries={activeBreweries} activeRestaurants={activeRestaurants}/>
                <FoodMap activeBreweries={activeBreweries} activeRestaurants={activeRestaurants}/>

    <AreaMap activeBreweries={activeBreweries} activeRestaurants={activeRestaurants}/>
    <BreweryMap activeBreweries={activeBreweries} activeRestaurants={activeRestaurants}/>
    <FoodMap activeBreweries={activeBreweries} activeRestaurants={activeRestaurants}/>

*/

export function Instructions(){
    return(
        <div className='instructionPanel'>
            <h1>{t("Instructions for virtual beer list")}</h1>
            <div>
                <h2>0.</h2>
            </div>
            <p>
                {t("set_up_instructions_0")} <a className='external-url' href='https://otaolut.fi/liput/'  target="_blank" rel="noopener noreferrer">otaolut.fi/liput</a>
            </p>
            <div>
                <h2>1.</h2>
            </div>
            <p>
                {t("set_up_instructions_1")}
            </p>
            <p>
                {t("personal_data_promise")}
            </p>
            <div>
                <h2>2.</h2>
            </div>
            <p>
                {t("app_features_1")} 
            </p>
            <p>
                {t("app_features_2")}
            </p>
            <div>
                <h2>3.</h2>
            </div>
            <p>
                {t("map_feature")}
            </p>
            <div>
                <h2>4.</h2>
            </div>
            <p>
                {t("pwa_feature")}
            </p>
        </div>
    )
}
