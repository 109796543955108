// GENERAL
import React from 'react';
import './App.css';

// COMPONENTS
import { Footer } from './components/general/footer';
import { BeerRaffle} from './features/beerRaffle/beerRaffle';
import { InfoBlock, AreaInfo } from './components/general/info';
import { BeerPlayContent } from './components/general/beerplaycontent';
import { ScheduleBlock } from './components/general/schedule';


// PAGES
export function InfoPage() {
  return (
    <>
      <div className="App">
        <InfoBlock/>
        <Footer/>
      </div>
    </>
  );
}

export function MapPage() {
  return (
      <div className="App">
        <AreaInfo/>
      </div>
  );
}

export function BeersPage() {
  return (
      <div className="App">
        <BeerPlayContent />
      </div>
  );
}

export function SchedulePage() {
  return (
      <div className="App">
        <ScheduleBlock />
      </div>
  );
}

export function RafflePage() {
  return (
      <div className="App">
        <BeerRaffle />
      </div>
  );
}