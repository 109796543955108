import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { connect, useDispatch } from 'react-redux';
import { UserModule } from './userModule';
import { createUser, startUserSession } from '../../services/userService'
import { drinkBeer, getBeers } from '../../services/beerService'

connect()(UserModule)

const initialState = {
    activeUserSession: false,
    activeUserCode: '',
    tastedBeers: [],
    userView: false,
    status: 'idle',
    selectedLanguage: 'fi'
  };


export const UserSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        clearUserSession(state, action) {
            if (state.activeUserCode !== '') {
                return {
                ...state,
                activeUserCode: '',
                tastedBeers: [],
                activeUserSession: false,
            }
        }
        },
        changeLanguageAction(state, action) {
            return {
                ...state,
                selectedLanguage: action.payload
            }
        }
    }, 
    extraReducers: builder => {
        builder
        .addCase(createUser.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(createUser.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.activeUserSession = true;
            state.activeUserCode = action.payload.userCode
            state.tastedBeers = action.payload.tastedBeers
        })
        .addCase(createUser.rejected,  (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(startUserSession.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(startUserSession.fulfilled, (state, action) => {
            state.status = 'succeeded'
            state.activeUserCode = action.payload._id
            state.activeUserSession = true;
            state.tastedBeers = action.payload.tastedBeers
          
        })
        .addCase(startUserSession.rejected,  (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(drinkBeer.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(drinkBeer.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(drinkBeer.rejected,  (state, action) => {
            state.status = 'failed'
        })
    }

})

export const selectCurrentUserCode = (state) => state.users.activeUserCode
export const selectActiveUserSession = (state) => state.users.activeUserSession
export const selectAllLoading = (state) => state.user.status === 'loading' || state.beers.status === 'loading';
export const selectSelectedLanguage = (state) => state.users.selectedLanguage;
export const { clearUserSession, changeLanguageAction } = UserSlice.actions

export default UserSlice.reducer;