import React, { useState, useEffect } from 'react';
import { selectBeersByWeekend } from "./BeerSlice";
import { useSelector, useDispatch } from 'react-redux';
//import { selectBeerTasted } from './BeerSlice'
import { drinkBeer, cancelDrinkBeer, flagBeer, cancelFlagBeer } from '../../services/beerService';
import { selectActiveUserSession } from '../users/userSlice';
import { useTranslation } from 'react-i18next';
import './beerModal.css';

export function Beer({beer}) {
    const activeSession = useSelector(selectActiveUserSession);
    const dispatch = useDispatch();

    const [waitState, setWaitState] = useState(false)
    
    function handleDrinkBeer(){
        if (!beer.tasted) {
            //setWaitState('notTasted')
            dispatch(drinkBeer(beer._id))
        } else {
            setWaitState('tasted')
            dispatch(cancelDrinkBeer(beer._id))
        }
    }
    
    function handleFlagBeer(){
        if (!beer.flagged) {
            dispatch(flagBeer(beer._id))
        } else {
            dispatch(cancelFlagBeer(beer._id))
        }
    }
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation();

    const imgSrc = beer?.brewery.toLowerCase().replace(' ', '-')
    
    function ImageWithFallback({ src}) {
        const fallback = '/og-img.png'; //backup-kuvan reitti
        const [imgSrc, setImgSrc] = useState(src);
        const onError = () => setImgSrc(fallback);
      
        return <img src={imgSrc ? imgSrc : fallback} onError={onError} alt='beerLogo' />;
    }

    return (
        <>
            {/* MODAL */}
            <div className={showModal ? 'displayBeerModalBackground' : 'hideBeerModalBackground'} onClick={() => setShowModal(false)}>
            </div>
            <div className={showModal ? 'displayBeerModal' : 'hideBeerModal'}>
                <div className='beerModalImgDiv'>
                    <ImageWithFallback src={'/breweries/'+imgSrc+'.png'}/>
                </div>
                <div className='beerModalInfoBlock'>
                    <h1>{beer?.name} {beer?.available ? '': <span className='additional-info-modal'>- Loppu</span>} {beer?.flagged ? <span></span> : <></>}</h1>
                    <p>{beer?.brewery}</p>
                    <h3>{beer?.style}, {beer?.ABV+' %'}</h3>
                </div>
                <div className='beerModalUrlBalls'>
                    { beer?._id > 100 && 
                        <a href={'https://untappd.com/beer/'+beer?._id} target="_blank" rel="noopener noreferrer">
                            Untappd
                        </a>
                    }
                    { activeSession &&
                        <div onClick={() => handleFlagBeer()}>
                            {beer?.flagged ? 'Peru tähti' : 'Anna tähti'}
                        </div>
                    }
                    { activeSession &&
                        <div  onClick={() => {handleDrinkBeer();setShowModal(false)}}>
                            { beer?.tasted ? t("cancel") :  t("taste") }
                        </div>
                    }
                </div>
                <div className='closeBeerModal' onClick={() => setShowModal(false)}><h1>{t("close")}</h1></div>
            </div>
            {/* ROW */}
            <div className={[activeSession && beer?.tasted ? 'beerRow beerRowTasted ' : 'beerRow ']+[beer?.flagged ? 'beerFlagged' : '' ]+[beer?.available ? '' : 'beerNotAvailable']}>
                <div className='brewery-img-container beerModalImgDivAnimate' onClick={() => setShowModal(true)}>
                    <ImageWithFallback src={'/breweries/'+imgSrc+'.png'}/>
                </div>
                <div className={activeSession ? 'beerInfoPart activeVersion' : 'beerInfoPart'} onClick={() => setShowModal(true)}>
                    <p className='beerName'>
                        {beer?.available ? '': <span className='additional-info-list'>Loppu: </span>} 
                        {beer?.name}
                        {beer?.flagged ? '' : <></>}
                    </p>
                    <p className='beerBrewery'>
                        <span>{beer?.brewery} </span>
                    </p>
                    <p className='beerStyleABV'>
                        <span>{beer?.style}, {beer?.ABV+' %'}</span>
                    </p>
                </div>
                { activeSession &&
                    <div className={
                        beer?.tasted ? 
                        'beerChekboxContainer' 
                        : 
                        'beerChekboxContainer beerLevelNot'
                        } 
                        onClick={() => handleDrinkBeer()}
                    >
                        <div className={ waitState === 'notTasted' ? 'beerCheckCircle not-tastedBeer pourBeerAway' : 'beerCheckCircle not-tastedBeer' } >
                                <div className={beer?.tasted ? 
                                    [ waitState === 'tasted' ? 'beerLevel beerLevelTasted beerRowLoadToUntaste' : 'beerLevel beerLevelTasted' ] 
                                    : 
                                    [ waitState === 'notTasted' ? 'beerLevel beerRowLoadToTaste' : 'beerLevel' ]
                                }></div>
                                <img src='/taste3.svg' alt='icon'/>
                        </div>
                        
                    </div>
                }
            </div>
            </>
    )
}

export function BeerWait() {
    const [rndBeer, setRndBeer] = useState('.');
    const beers = useSelector(selectBeersByWeekend);
    useEffect(() => {
        const interval = setInterval(() => 
        setRndBeer(beers[Math.floor(Math.random() * beers.length)]), 50);
        return () => {
            clearInterval(interval);
        };
    });
    
    return (
        <>
            {/* ROW */}
            <div className='beerRow '>
                <div className='brewery-img-container random-beer-img'>
                    <img src='/og-img.png' alt='beerLogo' />
                </div>
                <div className='beerInfoPart'>
                    <p className='beerName'>
                        {rndBeer?.name}
                    </p>
                    <p className='beerBrewery'>
                        <span>{rndBeer?.brewery} </span>
                    </p>
                    <p className='beerStyleABV'>
                        <span>{rndBeer?.style}, {rndBeer?.ABV+' %'}</span>
                    </p>
                </div>
            </div>
        </>
    )
}