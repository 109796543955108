import { createSelector, createSlice } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { getBeers, getSingleBeer } from '../../services/beerService';
import { Beer } from './Beer';

connect()(Beer)

export const initialState = {
    beers: [],
    status: 'idle',
    tasted: [],
    raffledBeerNumber: 0,
    selectedBeerStylesForRaffle: [],
    raffledBeer: null,
    weekend: 'BOTH',
    beerFilterString: ''
  };

export const BeerSlice = createSlice({
    name: 'beers',
    initialState: initialState,
    reducers: {
        raffleBeerNumber(state) {
            // Construct beer list that shall be used in the raffle.
            // Similar to selector selectNotDrinkenBeersWithinSelectedStyles.
            const beersIncludedInRaffle = beersForRaffleHelper(
                state.beers.filter(beer => beer.tasted !== undefined ? beer.tasted === false : true), 
                state.selectedBeerStylesForRaffle,
                state.weekend
            )
            // If range < 0, all beers have been tasted so raffled beer will be undefined.
            const range = beersIncludedInRaffle.length - 1;
            const randomNumber = Math.floor(Math.random() * range);
            return {
                ...state,
                raffledBeerNumber: randomNumber,
                raffledBeer: range >= 0 ? beersIncludedInRaffle[randomNumber] : undefined
            }
        },
        setBeerStatusToIdle(state) {
            return {
                ...state,
                status: 'idle'
            }
        },
        setRaffleStyleToInitial(state) {
            return {
                ...state,
                selectedBeerStylesForRaffle: [...new Set(state.beers?.map(beer => beer.style))]
            }
        },
        addRaffleStyle(state, style) {
            return {
                ...state,
                selectedBeerStylesForRaffle: 
                    !state.selectedBeerStylesForRaffle.includes(style.payload) ? 
                    state.selectedBeerStylesForRaffle.concat([style.payload])
                    :
                    state.selectedBeerStylesForRaffle
            }
        },
        removeRaffleStyle(state, style) {
            return {
                ...state,
                selectedBeerStylesForRaffle: 
                    state.selectedBeerStylesForRaffle.includes(style.payload) ? 
                    state.selectedBeerStylesForRaffle.filter((val) => val !== style.payload)
                    :
                    state.selectedBeerStylesForRaffle
            }  
        },
        updateRaffleBeerTastedStatus(state) {
            // Find the raffled beer from the (updated) list of beers
            // and make sure the 'tasted' status is correct, i.e. same as in DB.
            const newRaffleBeer = state.beers.find((beer) => beer._id === state.raffledBeer._id);
            return {
                ...state,
                raffledBeer: newRaffleBeer
            }
        },
        changeWeekendView(state, targetWeekend) {
            const newWeekend = targetWeekend.payload;
            return {
                ...state,
                weekend: newWeekend
            }
        },
        updateBeerFilterString(state, targetString) {
            return {
                ...state,
                beerFilterString: targetString.payload
            }
        }
    },

    extraReducers: builder => {
        builder
            .addCase(getBeers.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getBeers.fulfilled, (state, action) => {
                return {
                    ...state,
                    status: 'succeeded',
                    beers: action.payload,
                    selectedBeerStylesForRaffle: [...new Set(action.payload.map(beer => beer.style))]
                }
            })
            .addCase(getBeers.rejected,  (state, action) => {
                return {
                    ...state,
                    status: 'failed',
                    error: action.error.message
                }
            })
            .addCase(getSingleBeer.pending, (state, action) => {
                return {
                    ...state,
                    status: 'loading'
                }
            })
            .addCase(getSingleBeer.fulfilled, (state, action) => {
                return {
                    ...state,
                    status: 'succeeded',
                    beers: state.beers.map((beerInArr) => {
                        return beerInArr._id === action.payload._id ? { ...beerInArr, flagged: action.payload.flagged, tasted: action.payload.tasted } : beerInArr
                    }
                    )
                }
            })
            .addCase(getSingleBeer.rejected, (state, action) => {
                return {
                    ...state,
                    status: 'failed',
                    error: action.error.message
                }
            })
    }

})


export const { 
    tasteBeer, 
    raffleBeerNumber, 
    setBeerStatusToIdle, 
    setRaffleStyleToInitial, 
    addRaffleStyle, 
    removeRaffleStyle, 
    updateRaffleBeerTastedStatus,
    changeWeekendView,
    updateBeerFilterString
} = BeerSlice.actions

export const selectBeersStatus = (state) => state.beers.status;

export const selectFilterString = (state) => state.beers.beerFilterString;

export const selectWeekend = (state) => state.beers.weekend;

export const selectBeers = (state) => state.beers.beers;

export const selectBeersWithFilters = createSelector(
    selectBeers,
    selectFilterString,
    (beers, filterString) => { 
        if (filterString !== null || filterString !== undefined || filterString !== '') {
            return beers.filter( (beer) => 
                beer?.name?.toUpperCase().includes(filterString.toUpperCase()) ||
                beer?.style?.toUpperCase().includes(filterString.toUpperCase()) ||
                beer?.brewery?.toUpperCase().includes(filterString.toUpperCase())
        )
        } else {
            return beers
        }
        
    }
)

export const selectBeersByWeekend = createSelector(
    selectWeekend,
    selectBeersWithFilters,
    (weekend, beers) => {
        switch(weekend) {
            case 'BOTH':
                return beers;
            case 'FIRST':
                return beers.filter((beer) => beer.weekend === 'FIRST' || beer.weekend === 'BOTH');
            case 'SECOND':
                return beers.filter((beer) => beer.weekend === 'SECOND' || beer.weekend === 'BOTH');
            default:
                return beers;
        }

    }
)

export const selectBeerTasted = (state) => state.beers.tasted;

export const selectRaffledBeer = (state) => state.beers.raffledBeer;

export const selectSelectedBeerStylesForRaffle = (state) => {
    return state.beers.selectedBeerStylesForRaffle;
}

export const selectAtLeastOneStyleSelectedForRaffle = (state) => {
    return state.beers.selectedBeerStylesForRaffle?.length > 0
}

// Return unique styles of beers and convert into object with info on whether they are selected for raffle
export const selectBeerStyles = (state) => {
    return [...new Set(state.beers.beers
            .map(beer => beer.style))]
            .map(
                (style) => {
                        return {
                            style: style,
                            inRaffle: state.beers.selectedBeerStylesForRaffle.includes(style)
                        }
                }
            )
};

export const selectNotDrinkenBeers = (state) => state.beers.beers.filter(beer => 
    beer.tasted !== undefined ? beer.tasted === false : true
);

export const selectNotDrinkenBeersWithinSelectedStyles = createSelector(
    selectNotDrinkenBeers, 
    selectSelectedBeerStylesForRaffle, 
    (a, b) => {
        let res = [];
        try {
            for (let beerIndex = 0; beerIndex < a?.length; beerIndex++) {
                if (b.includes(a[beerIndex].style)) {
                    res = res.concat(a[beerIndex])
                }
            }
            return res;
        } catch {
            return undefined
        }
})

export const selectRaffledBeerNumber = (state) => state.beers.raffledBeerNumber;

export const selectRandomBeer = createSelector(selectNotDrinkenBeersWithinSelectedStyles, selectRaffledBeerNumber, (a, b) => {
    try{
        let index = Math.floor(Math.random() * a?.length)
        if(a[index]){
            return a[index]
        } else {
            return undefined
        }
    } catch {
        return undefined
    }
    
})

export const selectBeerStyleSelectedForRaffle = (state, style) => {
    if (style) {
        return state.beers.selectedBeerStylesForRaffle?.includes(style)
    } else {
        return true
    }
}

// Helper function to return beers that have not been tasted and are within
// the selected styles for the raffle.
export const beersForRaffleHelper = (notDrinkenBeers, selectedStylesForRaffle, weekend) => {
    let res = [];
    try {
        for (let beerIndex = 0; beerIndex < notDrinkenBeers?.length; beerIndex++) {
            if ((selectedStylesForRaffle.includes(
                notDrinkenBeers[beerIndex].style) && 
                (notDrinkenBeers[beerIndex].weekend === weekend || notDrinkenBeers[beerIndex].weekend === 'BOTH'))
            ) 
            {
                res = res.concat(notDrinkenBeers[beerIndex])
            }
        }
        return res;
    }
    catch {
        return undefined;
    }
}
export default BeerSlice.reducer;
