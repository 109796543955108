const KEY = "userState";
export function loadUsersState() {
  try {
    const serializedUserState = localStorage.getItem(KEY);
    if (!serializedUserState) return undefined;
    return JSON.parse(serializedUserState);
  } catch (e) {
    return undefined;
  }
}

export async function saveUsersState(state) {
  try {
    const serializedUserState = JSON.stringify(state.users);
    localStorage.setItem(KEY, serializedUserState);
  } catch (e) {
    // Ignore
  }
}
