import { createSlice } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { ScheduleBlock } from '../../components/general/schedule';
import { getProgram } from '../../services/beerService';

connect()(ScheduleBlock)

export const initialStateProgram = {
    program: [],
    programStatus: 'idle',
};

export const ProgramSlice = createSlice({
    name: 'program',
    initialState: initialStateProgram,
    reducers: {
        
    },
    extraReducers: builder => {
        builder
            .addCase(getProgram.pending, (state, action) => {
                return {
                    ...state,
                    programStatus: 'loading'
                }
            })
            .addCase(getProgram.fulfilled, (state, action) => {
                return {
                    ...state,
                    programStatus: 'succeeded',
                    program: action.payload
                }
            })
            .addCase(getProgram.rejected, (state, action) => {
                return {
                    ...state,
                    programStatus: 'failed'
                }
            })
           
    }

});

export const selectProgram = (state) => state.program.program

export const selectProgramStatus = (state) => state.program.programStatus

export const selectProgramTasting = (state) => state.program.program.filter((event)=> event.eventType === 'TASTING')

export const selectProgramPerformance = (state) => state.program.program.filter((event) => event.eventType === 'PERFORMANCE')

export default ProgramSlice.reducer;
