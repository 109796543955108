import { createAsyncThunk } from '@reduxjs/toolkit' 
import { apiBaseURL } from '../environment';
import { startUserSession } from './userService';
import { setBeerStatusToIdle, updateRaffleBeerTastedStatus} from '../features/beerlist/BeerSlice'
const axios = require('axios');

export const getBeers = createAsyncThunk('beers/beerlist', async (currentUserCode) => {
    let urlEndPoint;
    if (currentUserCode !== undefined && currentUserCode !== '') {
        urlEndPoint = apiBaseURL + '/beerlist?userCode=' + currentUserCode; 
    } else {
        urlEndPoint = apiBaseURL + '/beerlist'
    }
    try {
        const response = await axios.get(urlEndPoint)
        return response.data;
    } catch(error) {
        return error;
    }
}) 

export const getBooths = createAsyncThunk('booths/boothlist', async () => {
    let urlEndPoint;
    urlEndPoint = apiBaseURL + '/boothlist'
    try {
        const response = await axios.get(urlEndPoint);
        return response.data;
    } catch(error) {
        return error;
    }
}) 

export const getProgram = createAsyncThunk('program/getProgram', async () => {
    let urlEndPoint = apiBaseURL + '/program';
    try {
        const response = await axios.get(urlEndPoint);
        return response.data;
    } catch(error) {
        return error;
    }
})


export const getSingleBeer = createAsyncThunk('beers/getSingleBeer', async (beerID, thunkAPI) => {
    const userCode = thunkAPI.getState().users.activeUserCode;
    let urlEndPoint;
    console.log(beerID, userCode)
    if (userCode !== undefined && userCode !== '') {
        urlEndPoint = apiBaseURL + '/beerlist/' + userCode + '/' + beerID; 
    }
    try {
        const response = await axios.get(urlEndPoint)
        return response.data;
    } catch(error) {
        return error;
    }
}) 


// DRINKING

export const drinkBeer = createAsyncThunk('users/drinkBeer', async (beerID, thunkAPI) => {
    const userCode = thunkAPI.getState().users.activeUserCode;
    const raffleBeer = thunkAPI.getState().beers.raffledBeer;
    let urlEndPoint = apiBaseURL 
    + '/beerlist/' 
    + userCode
    + '/'
    + beerID
    + '/taste'
    if (userCode !== undefined) {
        let response;
        try {
            await axios.patch(urlEndPoint).then(res => {
                response = res
                thunkAPI.dispatch(getSingleBeer(beerID, userCode)).then((res) => {
                    if (raffleBeer?._id === beerID) {
                        thunkAPI.dispatch(updateRaffleBeerTastedStatus())
                    }
                    return response.data
                });
                
            })
            
        }
        catch(error) {
            return error
        }
        
      
    }
    
})

export const cancelDrinkBeer = createAsyncThunk('users/cancelDrinkBeer', async (beerID, thunkAPI) => {
    const userCode = thunkAPI.getState().users.activeUserCode
    let urlEndPoint = apiBaseURL 
    + '/beerlist/' 
    + userCode
    + '/'
    + beerID
    + '/regretTaste'
    if (userCode !== undefined) {
        let response;
        try {
            await axios.patch(urlEndPoint).then(res => {
                response = res
                thunkAPI.dispatch(getSingleBeer(beerID));
            })
            return response.data
        }
        catch(error) {
            return error
        }
    }
})

// FLAGGING

export const flagBeer = createAsyncThunk('users/flagBeer', async (beerID, thunkAPI) => {
    const userCode = thunkAPI.getState().users.activeUserCode
    let urlEndPoint = apiBaseURL 
    + '/beerlist/' 
    + userCode
    + '/'
    + beerID
    + '/flag'
    if (userCode !== undefined) {
        let response;
        try {
            await axios.patch(urlEndPoint).then(res => {
                response = res
                thunkAPI.dispatch(getSingleBeer(beerID));
            })
            return response.data
        }
        catch(error) {
            return error
        }
        
      
    }
    
})

export const cancelFlagBeer = createAsyncThunk('users/cancelFlagBeer', async (beerID, thunkAPI) => {
    const userCode = thunkAPI.getState().users.activeUserCode
    let urlEndPoint = apiBaseURL 
    + '/beerlist/' 
    + userCode
    + '/'
    + beerID
    + '/regretFlag'
    if (userCode !== undefined) {
        let response;
        try {
            await axios.patch(urlEndPoint).then(res => {
                response = res
                thunkAPI.dispatch(getSingleBeer(beerID));
            })
            return response.data
        }
        catch(error) {
            return error
        }
    }
})

