import './styles/bubbles.css'

export function Foam(){

    return(
          <div id="container-foam">
              <div className="beer-foam">
                <div className="foam-1"></div>
                <div className="foam-2"></div>
                <div className="foam-3"></div>
                <div className="foam-4"></div>
                <div className="foam-5"></div>
                <div className="foam-6"></div>
                <div className="foam-7"></div>
                <div className="foam-8"></div>
                <div className="foam-9"></div>
                <div className="foam-10"></div>
                <div className="foam-11"></div>
                <div className="foam-12"></div>
                <div className="foam-13"></div>
                <div className="foam-14"></div>
                <div className="foam-15"></div>
              </div>
          </div>
    )
}

export function LiquidBubbles(){

return(
    <div id="container-liquid">
        <div id="liquid">
          <div className="bubble bubble1"></div>
          <div className="bubble bubble2"></div>
          <div className="bubble bubble3"></div>
          <div className="bubble bubble4"></div>
          <div className="bubble bubble5"></div>
          <div className="bubble bubble6"></div>
        </div>
    </div>
)
}