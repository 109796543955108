import './styles/header.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'
//import { UserModule } from '../../features/users/userModule'
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Foam, LiquidBubbles } from './bubbles';

import { UserPanelNavibar } from '../../features/users/userModule';
import { useDispatch } from 'react-redux';
import { changeLanguageAction } from '../../features/users/userSlice';

//import { Bubbles } from './bubbles';


export function MiniHeader({darkMode = false}){
  const dispatch = useDispatch();
  const [showNavi, setShowNavi] = useState(false)
  const changeLanguage = (lan) => {
    i18next.changeLanguage(lan)
    dispatch(changeLanguageAction(lan))
  }
  const { t } = useTranslation();

  const lanOptions = {
    fi: "en",
    en: "fi"
  }

  var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    );

  const [loginActive, setLoginActive] = useState(false)

  function handleLoginActive(target) {
    setLoginActive(target)
  }


  return(
    <>
      <div className={darkMode ? "App-mini-header header-darkMode": "App-mini-header" }>
          <div onClick={() => changeLanguage(lanOptions[i18next.language])} className='lanIndicator'>{i18next.language}</div>
          <img className='generalheader-logo' onClick={() => setShowNavi(!showNavi)} src='/icons/otaolut_logoteksti_offwhite.svg' alt='icon'/>
          <div className={showNavi ? 'naviToggler navi-open': 'naviToggler navi-closed'} onClick={() => setShowNavi(!showNavi)}>|||</div>
      </div>
      <div className={showNavi ? 'showNavigation' : 'hideNavigation'}>
        <div className='App-navi-user'>
          <Foam/>
          <UserPanelNavibar setLoginActive={(e) => handleLoginActive(e)}/>
        </div>
        <div className='App-navi-general'>
          <LiquidBubbles/>
          <div className='app-navi-general-content'>
            <span className='App-navi-lan'>
              <span onClick={() => changeLanguage("fi")} className={i18next.language === 'fi' ? 'activeLanguage' : ''}>Suomeksi</span>
              <span> / </span>
              <span onClick={() => changeLanguage("en")} className={i18next.language === 'en' ? 'activeLanguage' : ''}>In English</span>
            </span>
            <Link to='/' onClick={() => setShowNavi(!showNavi)}>{t("info")}</Link>
            <Link to='/map' onClick={() => setShowNavi(!showNavi)}>{t("map")}</Link>
            <Link to='/schedule' onClick={() => setShowNavi(!showNavi)}>{t('schedule')}</Link>
            <Link to='/beers' onClick={() => setShowNavi(!showNavi)}>{t("beers")}</Link>
          </div>
        </div>
      </div>
    </>
  )
}
/*
export function MiniHeader(){
    const [showNavi, setShowNavi] = useState(false)
    return(
      <>
        <header className="App-mini-header" onClick={() => setShowNavi(!showNavi)}>
            <img className='generalheader-logo' src='/icons/otaolut_logoteksti_offwhite.svg' alt='icon'/>
            <div className="menu-icon" >
              <BurgerIcon showNavi={showNavi}/>
            </div>
            </header>
            <div className={showNavi ? 'showNavigation' : 'hideNavigation'}>
              <div className='App-navi-general'>
                <Link to='/'><div> Etusivu</div></Link>
                <Link to='/info'><div> Info</div></Link>
                <Link to='/map'><div> Kartta</div></Link>
                <Link to='/beers'><div> Oluet</div></Link>
                <a onClick={() => setShowNavi(false)}><div>SULJE TODO</div></a>
                </div>
            </div>
          </>
        )
    }




          {true ?
          <>
            <h3>Hi user X</h3>
            <div className='navi-user-logout'>
              <h3>Logout</h3>
            </div>
          </>
          :
          <>
            <h3>Not logged in!</h3>
            <div className='navi-login-part'>
              
              {
                true ? 
                <>
                  <div>
                    Minulla on jo tunnus
                  </div>
                  <div>
                    Luo tunnus
                  </div>
                </>
                :
                <>
                <h2>hehe</h2>
                </>
              }
            </div>
          </>
          }

*/
