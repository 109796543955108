import './styles/schedule.css'
import { useEffect, useState } from "react";
import { MiniHeader } from './header'
import AreaMap from './areamap'
import { UserModule } from '../../features/users/userModule'
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProgram } from '../../services/beerService';
import { selectProgramStatus, selectProgramTasting, selectProgramPerformance } from '../../features/boothlist//programSlice';
import { selectSelectedLanguage } from '../../features/users/userSlice';

export function ScheduleBlock(){
    const { t } = useTranslation();

   const programStatus = useSelector(selectProgramStatus);
   const tastingProgram = useSelector(selectProgramTasting);
   const performanceProgram = useSelector(selectProgramPerformance);
   const dispatch = useDispatch();

    useEffect(() => {
        if (programStatus === 'idle') {
                dispatch(getProgram())
            }
        }, [dispatch, programStatus])


    return (
        <div className='centerViewBlock'>
            <MiniHeader darkMode='true'/>

            <div className='viewBlock scheduleMain'>
                <div className='headBlock'>
                    <h1>{t("Ota olut program")}</h1>
                    <div>{t("Ota olut features some chill program throughout the weekends. Scroll below and make sure not to miss it!")}</div>
                </div>
                <span className='middle-line'></span>
                <h1 className='scheduleBlockHeading'>{t("Tastings")}</h1>
                <div>
                {tastingProgram.map((tasting, key) => ( 
                    <div>
                        <ScheduleItem key={key+tasting._id.$oid} event={tasting}/>
                    </div>
                ))
                }
                </div>
                <span className='middle-line'></span>
                <h1 className='scheduleBlockHeading'>{t("Music")}</h1>
                <div>
                {performanceProgram.map((performance, key) => ( 
                    <div>
                        <ScheduleItem key={performance._id.$oid} event={performance}/>
                    </div>
                ))
                }
                </div>
                <span className='middle-line'></span>
            </div>
        </div>
    )
}

export function ScheduleItem(props) {
    const lan = useSelector(selectSelectedLanguage);
    return(
        <div className="scheduleItem">
             {
                lan === 'fi' && 
                <div className="scheduleDescription">
                    <div className='flexSchedule'>
                        <div className='scheduleHeading'>{props?.event?.headingFi}</div>
                        <div className='scheduleTime'>{new Date(props?.event?.dateTime.$date).toLocaleString()}</div>
                    </div>
                    <div className='scheduleDescription'>{props?.event?.descriptionFi}</div>
                </div>
             }
             {
                lan === 'en' && 
                <div className="scheduleDescription">
                    <div className='flexSchedule'>
                        <div  className='scheduleHeading'>{props?.event?.headingEn}</div> 
                        <div className='scheduleTime'>{new Date(props?.event?.dateTime.$date).toLocaleString()}</div> 
                     </div>              
                    <div className='scheduleDescription'>{props?.event?.descriptionEn}</div>
                </div>
                
             }
            <div>
                {props?.event?.price !== undefined && props.event.price !== null && props.event.price !== 0  &&
                    <div className='schedulePrice'>{props.event.price} €</div>
                }
            </div>
        </div>
    )
}